import React, { useState } from 'react';
import YouTube from 'react-youtube';
import styled from 'styled-components';
import VideoControls from './VideoControls';
import '../css/youtube_embed_container.css';

const YouTubeEmbed = React.forwardRef(
  ({ title, videoId, hasSound, hideControls }, ref) => {
    const [playing, setPlaying] = useState(true);
    const [muted, setMuted] = useState(false);
    const [hover, setHover] = useState(false);
    const [video, setVideo] = useState();
    const [isReady, setIsReady] = useState(false);
    const options = {
      width: '100%',
      height: '100%',
      playerVars: {
        playlist: videoId,
        rel: 0,
        showinfo: 0,
        loop: 1,
        controls: 0,
        modestbranding: 1,
        playsinline: 1,
        autoplay: 1,
      },
    };

    const onMouseEnter = () => setHover(true);

    const onMouseLeave = () => setHover(false);

    const onReady = ({ target: videoRef }) => {
      videoRef.mute();
      videoRef.playVideo();

      setVideo(videoRef);
      setMuted(true);
      setIsReady(true);
    };

    const onStateChange = ({ data: state }) => setPlaying(state === 1);

    const togglePlay = e => {
      if (playing) {
        video.pauseVideo();
      } else {
        video.playVideo();
      }

      e.stopPropagation();
    };

    const toggleMute = e => {
      if (muted) {
        video.unMute();
        setMuted(false);
      } else {
        video.mute();
        setMuted(true);
      }

      e.stopPropagation();
    };

    return (
      <FullSizeEmbedContainer
        ref={ref}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        title={title}
      >
        <YouTube
          id=""
          className=""
          containerClassName="youtube_embed_container"
          videoId={videoId}
          opts={options}
          onReady={onReady}
          onStateChange={onStateChange}
        />

        <YouTubeCover />

        <VideoControls
          hide={(!hover && playing) || !isReady || hideControls}
          playing={playing}
          muted={muted}
          hasSound={hasSound}
          togglePlay={togglePlay}
          toggleMute={toggleMute}
        />
      </FullSizeEmbedContainer>
    );
  }
);

export default YouTubeEmbed;

const YouTubeCover = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const FullSizeEmbedContainer = styled.div`
  width: 100%;
  height: 100%;
`;
