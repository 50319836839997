import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import VideoControls from './VideoControls';

const Video = React.forwardRef(({ src, hideControls, hasSound }, ref) => {
  const [playing, setPlaying] = useState(true);
  const [muted, setMuted] = useState(true);
  const [hover, setHover] = useState(false);
  const video = useRef();

  const onMouseEnter = () => setHover(true);

  const onMouseLeave = () => setHover(false);

  const togglePlay = e => {
    if (playing) {
      video.current.pause();
      setPlaying(false);
    } else {
      video.current.play();
      setPlaying(true);
    }

    e.stopPropagation();
  };

  const toggleMute = e => {
    if (muted) {
      video.current.muted = false;
      setMuted(false);
    } else {
      video.current.muted = true;
      setMuted(true);
    }

    e.stopPropagation();
  };

  return (
    <div ref={ref} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <FullSizeVideo ref={video} playsInline muted autoPlay loop>
        <source src={require(`../images/${src}.mp4`)} type="video/mp4" />
      </FullSizeVideo>

      <VideoControls
        hide={(!hover && playing) || hideControls}
        playing={playing}
        muted={muted}
        hasSound={hasSound}
        togglePlay={togglePlay}
        toggleMute={toggleMute}
      />
    </div>
  );
});

export default Video;

const FullSizeVideo = styled.video`
  width: 100%;
  height: 100%;
`;
