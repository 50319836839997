import styled from 'styled-components';
import theme from 'styled-theming';

const gridColumn = theme.variants('mode', 'position', {
  left_tiny_image: { normal: 'left-tiny-image-start / left-tiny-image-end' },
  center_tiny_image: {
    normal: 'center-tiny-image-start / center-tiny-image-end',
  },
  right_tiny_image: {
    normal: 'right-tiny-image-start / right-tiny-image-end',
  },

  offset_triplet_1: { normal: 'offset-triplet-start-1 / offset-triplet-end-1' },
  offset_triplet_2: { normal: 'offset-triplet-start-2 / offset-triplet-end-2' },
  offset_triplet_3: { normal: 'offset-triplet-start-3 / offset-triplet-end-3' },

  inset_center: { normal: 'inset-center-start / inset-center-end' },

  left_text: { normal: 'left-text-start / left-text-end' },
  right_text: { normal: 'right-text-start / right-text-end' },
  full_text: { normal: 'left-text-start / right-text-end' },

  left_half: { normal: 'left-image-start / left-image-end' },
  right_half: { normal: 'right-image-start / grid-end' },
  full: { normal: 'left-image-start / grid-end' },

  left_third: { normal: 'first-image-start / first-image-end' },
  center_third: { normal: 'second-image-start / second-image-end' },
  right_third: { normal: 'third-image-start / third-image-end' },
});

const AssetPosition = styled.figure`
  grid-column: ${gridColumn};
`;

export default AssetPosition;
