import React from 'react';
import styled from 'styled-components';
import { assetPlaceholderIndex } from '../utils/indexes';

const AssetPlaceholder = ({ width, height }) => (
  <AssetPlaceholderContainer>
    <Placeholder
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <rect width={width} height={height}></rect>
    </Placeholder>
  </AssetPlaceholderContainer>
);

export default AssetPlaceholder;

const AssetPlaceholderContainer = styled.div`
  z-index: ${assetPlaceholderIndex};
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

const Placeholder = styled.svg`
  height: 100%;
  left: 0;
  position: absolute;
  fill: var(--theme-placeholder);
  top: 0;
  width: 100%;
`;
