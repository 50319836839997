import React from 'react';
import styled from 'styled-components';
import theme from 'styled-theming';
import {
  maxColWidthPx,
  minColWidthPx,
  comfortableColWidth,
  minColWidth,
  maxColWidth,
} from '../utils/gridWidths';

const minMax = `minmax(${`${minColWidth}px`}, ${`${maxColWidth}px`})`;

const gridTemplateColumnsMedium = theme.variants('mode', 'position', {
  left_half: {
    normal: `${minMax} [caption-start] repeat(6, ${minMax}) [caption-end] ${minMax}`,
  },
  right_half: {
    normal: `${minMax} [caption-start] repeat(6, ${minMax}) [caption-end] ${minMax}`,
  },
  full: {
    normal: `${minMax} [caption-start] repeat(7, ${minMax}) [caption-end] repeat(7, ${minMax}) ${minMax}`,
  },
});

const gridTemplateColumnsWide = theme.variants('mode', 'position', {
  left_half: {
    normal: `1fr [caption-start] repeat(6, ${minMax}) [caption-end] ${minMax}`,
  },
  right_half: {
    normal: `${minMax} [caption-start] repeat(6, ${minMax}) [caption-end] 1fr`,
  },
  full: {
    normal: `1fr [caption-start] repeat(7, ${minMax}) [caption-end] repeat(7, ${minMax}) 1fr`,
  },
});

const AssetCaption = ({ position, children }) => (
  <Caption position={position}>
    <Text>{children}</Text>
  </Caption>
);

export default AssetCaption;

const Caption = styled.figcaption`
  font-size: 16px;
  font-style: italic;
  margin: 0.5em 0 0;
  display: grid;
  justify-content: center;
  grid-column-gap: ${maxColWidthPx};

  @media screen and (max-width: ${`${20 * comfortableColWidth}px`}) {
    grid-column-gap: ${minColWidthPx};
  }

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }

  @media screen and (max-width: ${`${32 * comfortableColWidth}px`}) {
    padding-left: 2px;
    padding-right: 2px;

    grid-template-columns:
      ${minMax}
      [caption-start] repeat(7, ${minMax})
      [caption-end] ${minMax};
  }

  @media screen and (min-width: ${`${32 * comfortableColWidth}px`}) {
    grid-template-columns: ${gridTemplateColumnsMedium};
  }

  @media screen and (min-width: ${`${36 * maxColWidth}px`}) {
    grid-template-columns: ${gridTemplateColumnsWide};
  }
`;

const Text = styled.p`
  grid-column: caption-start / caption-end;
`;
