import React from 'react';
import styled, { keyframes, css } from 'styled-components';

const VideoControls = ({
  hide,
  playing,
  muted,
  hasSound,
  togglePlay,
  toggleMute,
}) => {
  const showBackground = (hasSound && muted) || !playing;

  return (
    <VideoControlsContainer showBackground={showBackground} hidden={hide}>
      <Control onClick={togglePlay} aria-label="Toggle Play">
        {playing ? (
          <img alt="" src={require(`../images/pause.svg`)} />
        ) : (
          <img alt="" src={require(`../images/play.svg`)} />
        )}
      </Control>

      {hasSound && (
        <Control onClick={toggleMute} aria-label="Toggle Mute">
          {muted ? (
            <img alt="" src={require(`../images/unmute.svg`)} />
          ) : (
            <img alt="" src={require(`../images/mute.svg`)} />
          )}
        </Control>
      )}
    </VideoControlsContainer>
  );
};

export default VideoControls;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const VideoControlsContainer = styled.div`
  animation: ${fadeIn} 0.5s ease;
  bottom: -1px;
  position: absolute;
  right: -1px;
  transition: opacity 0.2s;
  white-space: nowrap;

  &:hover {
    background-color: var(--theme-secondary);
  }

  ${props =>
    props.showBackground &&
    css`
      background-color: var(--theme-secondary);
    `}

  ${props =>
    props.hidden &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`;

const Control = styled.button`
  background: transparent;
  border: 0;
  cursor: pointer;
  max-width: 12vw;
  outline: 0;
  padding: 0.5rem;
  -webkit-appearance: none;
`;
