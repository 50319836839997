import React from 'react';
import styled from 'styled-components';

const Picture = React.forwardRef(
  ({ src, alt, singleSource = false, type = 'png' }, ref) => (
    <picture>
      {type === 'png' && !singleSource && (
        <source
          srcSet={require(`../images/${src}_750w.webp`)}
          media="(max-width: 750px)"
          type="image/webp"
        />
      )}

      {!singleSource && (
        <source
          srcSet={require(`../images/${src}_750w.${type}`)}
          media="(max-width: 750px)"
          type="image/png"
        />
      )}

      {type === 'png' && (
        <source
          srcSet={require(`../images/${src}.webp`)}
          media="(min-width: 751px)"
          type="image/webp"
        />
      )}

      <FullSizeImage
        ref={ref}
        src={require(`../images/${src}.${type}`)}
        alt={alt}
        loading="lazy"
      />
    </picture>
  )
);

export default Picture;

const FullSizeImage = styled.img`
  width: 100%;
  height: 100%;
`;
